import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import ErrorScreen from '../../components/error-screen';
import { registrarEmpresa, validarNit } from '../../api/api-empresa';
import spinner from "./spinner4.svg";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/16/solid';
import { validarNombreUsuario } from '../../api/api-usuario-web';

export default function SignUpScreen() {
    const [formData, setFormData] = useState({
        nameCompany: '',
        nitCompany: '',
        telephoneCompany: '',
        addressCompany: '',
        userName: '',
        password: '',
        confirmPassword: '',
    });

    const history = useHistory();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");

    const charLimits = {
        nameCompany: 128,
        nitCompany: 45,
        telephoneCompany: 45,
        addressCompany: 512,
        userName: 64,
        password: 16,
        confirmPassword: 16,
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= charLimits[name]) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validar = () => {
        try {
            let result = { error: true, message: "" };
            let message = "";
            if (formData.nameCompany.trim().length === 0) {
                message = message + "Debe ingresar nombre empresa" + "\n";
            }
            if (formData.nitCompany.trim().length === 0) {
                message = message + "Debe ingresar nit empresa" + '\n';
            }
            if (message.length > 0) {
                result.error = true;
                result.message = message;
            } else {
                result.error = false;
            }
            return result;
        } catch (err) {
            return { error: true, message: err };
        }
    }

    const handleNextStep = async (e) => {
        try {
            // console.log('handleNextStep');
            // console.log(formData);
            e.preventDefault();
            const isValid = validar();
            if (isValid.error === true) {
                alert(isValid.message);
                return;
            }
            if (isValid.error === false) {
                setLoading(true);
                const nitValido = await validarNit(formData);
                // console.log('nitValido');
                // console.log(nitValido);
                if (nitValido.error === true) {
                    alert(nitValido.message);
                    setLoading(false);
                    return;
                }
                setLoading(false);
                if (nitValido.error === false) {
                    if (nitValido.total > 0) {
                        alert('Nit ya se encuentra registrado');
                        return;
                    }
                    setStep(2);
                }
            } else {
                alert(isValid.message);
            }
            setLoading(false);
        } catch (err) {
            console.log('handleNextStep err');
            console.log(err);
        }
    };

    const handleGoBack = () => {
        window.location.href = "/login"; // Adjust the URL as needed
    };

    const validarInputsCreateAcccount = () => {
        try {
            let result = { error: true };
            let message = "";
            if (formData.userName.trim().length === 0) {
                message = message + "Debe ingresar nombre usuario" + '\n';
            }
            if (formData.password.trim().length === 0) {
                message = message + "Debe ingresar password" + '\n';
            }
            if (formData.confirmPassword.trim().length === 0) {
                message = message + "Debe ingresar confirmar password" + '\n';
            }
            if (message.length === 0) {
                result.error = false;
            }
            if (message.length > 0) {
                result.error = true;
                result.message = message;
            }
            return result;
        } catch (err) {
            console.log('validarInputsCreateAcccount err');
            console.log(err);
            return { error: true, message: err };
        }
    }

    const createAccount = async (e) => {
        try {
            // console.log('createAccount');
            e.preventDefault();
            setPasswordError("");
            // console.log('formData');
            // console.log(formData);
            const validateInputs = validarInputsCreateAcccount();
            if (validateInputs.error === true) {
                alert(validateInputs.message);
                return;
            }
            setLoading(true);
            const nombreUsuario = await validarNombreUsuario(formData);
            // console.log('nombreUsuario');
            // console.log(nombreUsuario);
            setLoading(false);
            if (nombreUsuario.error === true) {
                alert(nombreUsuario.message);
                return;
            }
            if (nombreUsuario.error === false) {
                if (nombreUsuario.total !== 0) {
                    alert('Nombre usuario ya se encuentra registrado');
                    return;
                }
            }
            if (formData.password !== formData.confirmPassword) {
                setPasswordError("Passwords no coinciden");
                return;
            }
            setLoading(true);
            const offsetInMinutes = new Date().getTimezoneOffset(); // Offset in minutes
            const offsetInHours = -offsetInMinutes / 60; // Convert to hours and invert the sign
            // console.log(offsetInHours); // Example output: -5 or -4
            formData.timeZone = offsetInHours;
            const result = await registrarEmpresa(formData);
            console.log('result');
            console.log(result);
            if (result.error === false) {
                localStorage.setItem("accessTokenUltimaMilla", result.token);
                localStorage.setItem("idEmpresaUltimaMilla", result.idEmpresa);
                history.push(`/dashboard`);
            }
        } catch (err) {
            console.log('createAccount err');
            console.log(err);
            setLoading(false);
        }
    }

    const render = () => {
        try {
            return (
                <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                    <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                        {step === 1 && (
                            <>
                                <h2 className="text-2xl font-bold text-center text-[#0F3457] mb-6">Registrarse</h2>
                                <form onSubmit={handleNextStep}>
                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="nameCompany">
                                            Nombre Empresa<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="nameCompany"
                                            name="nameCompany"
                                            value={formData.nameCompany}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Enter company name"
                                            maxLength={charLimits.nameCompany}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.nameCompany.length}/{charLimits.nameCompany}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="nitCompany">
                                            NIT<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="nitCompany"
                                            name="nitCompany"
                                            value={formData.nitCompany}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Enter company NIT"
                                            maxLength={charLimits.nitCompany}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.nitCompany.length}/{charLimits.nitCompany}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="telephoneCompany">
                                            Telefono
                                        </label>
                                        <input
                                            type="text"
                                            id="telephoneCompany"
                                            name="telephoneCompany"
                                            value={formData.telephoneCompany}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Enter telephone number"
                                            maxLength={charLimits.telephoneCompany}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.telephoneCompany.length}/{charLimits.telephoneCompany}</p>
                                    </div>

                                    <div className="mb-6">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="addressCompany">
                                            Direccion
                                        </label>
                                        <input
                                            type="text"
                                            id="addressCompany"
                                            name="addressCompany"
                                            value={formData.addressCompany}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Enter company address"
                                            maxLength={charLimits.addressCompany}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.addressCompany.length}/{charLimits.addressCompany}</p>
                                    </div>
                                    {loading === false &&
                                        <button
                                            type="submit"
                                            className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300"
                                        >
                                            Siguiente
                                        </button>
                                    }
                                    {loading === true &&
                                        <button
                                            className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300 flex justify-center items-center"
                                        >
                                            <img className="spinner" alt="spinner" src={spinner} />
                                        </button>

                                    }
                                </form>
                                <p
                                    onClick={handleGoBack}
                                    className="text-center text-[#0F3457] mt-4 cursor-pointer hover:underline"
                                >
                                    Atras
                                </p>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <h2 className="text-2xl font-bold text-center text-[#0F3457] mb-6">Crear Cuenta</h2>
                                <form onSubmit={createAccount}>
                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="userName">
                                            Usuario<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userName"
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Ingrese Usuario"
                                            maxLength={charLimits.userName}
                                            onChange={handleChange}
                                            value={formData.userName}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.userName.length}/{charLimits.userName}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="password">
                                            Password<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type={showPassword === false ? "password" : "text"}
                                                id="password"
                                                name="password"
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                                placeholder="Enter password"
                                                maxLength={charLimits.password}
                                                onChange={handleChange}
                                                value={formData.password}
                                            />
                                            {showPassword === true &&
                                                <EyeIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowPassword(!showPassword)}
                                                    style={{ transition: "opacity 0.3s ease-in-out", }}
                                                />
                                            }
                                            {showPassword === false &&
                                                <EyeSlashIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowPassword(!showPassword)}
                                                    style={{ transition: "opacity 0.3s ease-in-out", }}
                                                />
                                            }
                                        </div>

                                        <p className="text-sm text-gray-500 flex justify-end">{formData.password.length}/{charLimits.password}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="confirmPassword">
                                            Confirmar Password<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type={showConfirmPassword === false ? "password" : "text"}
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                                placeholder="Confirm password"
                                                maxLength={charLimits.password}
                                                onChange={handleChange}
                                                value={formData.confirmPassword}
                                            />
                                            {showConfirmPassword === true &&
                                                <EyeIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                            }
                                            {showConfirmPassword === false &&
                                                <EyeSlashIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                            }
                                        </div>
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.confirmPassword.length}/{charLimits.password}</p>
                                    </div>
                                    {passwordError.length > 0 &&
                                        <span className="text-red-500">{passwordError}</span>
                                    }
                                    <button
                                        type="submit"
                                        className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300"
                                    >
                                        Registrarse
                                    </button>
                                    <p
                                        onClick={() => { setStep(1); }}
                                        className="text-center text-[#0F3457] mt-4 cursor-pointer hover:underline"
                                    >
                                        Atras
                                    </p>
                                </form>
                            </>
                        )}
                    </div>
                </div >
            );
        } catch (err) {
            console.log('sign up err');
            console.log(err);
            return (
                <ErrorScreen />
            );
        }
    }

    return render();
};
