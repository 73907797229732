import React from 'react';

const ErrorScreen = () => {
    const handleGoBack = () => {
        window.location.href = "/login"; // Adjust the URL as needed
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 text-center">
                <h2 className="text-2xl font-bold text-[#0F3457] mb-6">Oops, Something Went Wrong</h2>
                <p className="text-gray-700 mb-6">
                    We encountered an error while processing your request. Please try again.
                </p>
                <button
                    onClick={handleGoBack}
                    className="bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300"
                >
                    Go Back to Login
                </button>
            </div>
        </div>
    );
};

export default ErrorScreen;
