import { POST, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function verifyLogin(usuario, contrasenia) {
  try {
    let data = { error: true };
    const { code, body, message } = await fetch(
      "https://gestiondeflota.boltrack.net/apiultimamillav3/api/autenticacion/login-web",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: POST,
        body: JSON.stringify({ usuario, contrasenia }),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      data.error = false;
      data.body = body;
    } else {
      data.error = true;
      data.message = message;
      data.body = null;
    }
    // console.log('data');
    // console.log(data);
    return data;
  } catch (err) {
    console.log("verifyLogin err");
    console.log(err);
    return { error: true };
  }
}

export async function getUserInfo() {
  try {
    let data = null;
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/apiultimamillav3/api/autenticacion/user-info",
      {
        headers: getHeaders(),
        method: POST,
        body: JSON.stringify({}),
      }
    ).then((res) => res.json());
    // console.log('code');
    // console.log(code);
    // console.log('body');
    // console.log(body);
    if (code === SUCCESS) {
      data = body;
    }
    return data;
  } catch (err) {
    console.log("getUserInfo err");
    console.log(err);
    return null;
  }
}