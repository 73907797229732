import { FAILURE, POST, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function validarNombreUsuario(obj) {
    try {
        let data = { error: true };
        const { code, message, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apiultimamillav3/api/usuario-web/get-count-nombre-usuario",
            {
                headers: getHeaders(false),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            data.error = false;
            if (Array.isArray(body) && body.length > 0) {
                data.total = body[0].total;
            }
        }
        if (code === FAILURE) {
            data.error = true;
            data.message = message;
        }
        return data;
    } catch (err) {
        console.log("validarNombreUsuario err");
        console.log(err);
        return { error: true, message: err };
    }
}

export async function obtenerInfoUsuario(obj) {
    try {
        let data = { error: true };
        const { code, message, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apiultimamillav3/api/usuario-web/obtener-info-usuario",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            data.error = false;
            data.body = body;
        }
        if (code === FAILURE) {
            data.error = true;
            data.message = message;
        }
        return data;
    } catch (err) {
        console.log("obtenerInfoUsuario err");
        console.log(err);
        return { error: true, message: err };
    }
}