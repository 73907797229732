import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import AboutUs from "./about-us";
import Footer from "./footer";
import Gallery from "./gallery";
import VisitUs from "./visit-us";
import WhyUs from "./why-us";
import OurServices from "./our-services";

export default function LandingScreen() {

    const history = useHistory();

    useEffect(() => {
        const toggleMenu = () => {
            const navToggle = document.getElementsByClassName("toggle");
            for (let i = 0; i < navToggle.length; i++) {
                navToggle.item(i).classList.toggle("hidden");
            }
        };

        const hamburger = document.getElementById("hamburger");
        hamburger.addEventListener("click", toggleMenu);

        return () => {
            hamburger.removeEventListener("click", toggleMenu);
        };
    }, []);

    const render = () => {
        try {
            return (
                <>
                    {/* <!-- nav bar section --> */}
                    <nav class="flex flex-wrap items-center justify-between p-3 bg-[#e8e8e5]">
                        <div class="text-xl">Bappa Flour mill</div>
                        <div class="flex md:hidden">
                            <button id="hamburger">
                                <img class="toggle block" src="https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png" width="40" height="40" />
                                <img class="toggle hidden" src="https://img.icons8.com/fluent-systems-regular/2x/close-window.png" width="40" height="40" />
                            </button>
                        </div>
                        <div class=" toggle hidden w-full md:w-auto md:flex text-right text-bold mt-5 md:mt-0 md:border-none">
                            <a href="#home" class="block md:inline-block hover:text-blue-500 px-3 py-3 md:border-none">Home
                            </a>
                            <a href="#services" class="block md:inline-block hover:text-blue-500 px-3 py-3 md:border-none">Services
                            </a>
                            <a href="#aboutus" class="block md:inline-block hover:text-blue-500 px-3 py-3 md:border-none">About us
                            </a>
                            <a href="#gallery" class="block md:inline-block hover:text-blue-500 px-3 py-3 md:border-none">Gallery
                            </a>
                            <a href="#contactUs" class="block md:inline-block hover:text-blue-500 px-3 py-3 md:border-none">Visit Us
                            </a>
                        </div>

                        <div className="toggle w-full text-end hidden md:flex md:w-auto px-2 py-2 md:rounded cursor-pointer"
                            onClick={() => {
                                console.log('navigate to log in');
                                // history.push(`/login`);
                            }}>

                            <div className="flex justify-end">
                                <div className="flex items-center h-10 w-30 rounded-md bg-[#c8a876] text-white font-medium p-2">
                                    Iniciar Sesion
                                </div>
                            </div>
                        </div>


                    </nav>
                    {/* <!-- hero seciton --> */}
                    <div class="relative w-full h-[320px]" id="home">
                        <div class="absolute inset-0 opacity-70">
                            <img src="https://image1.jdomni.in/banner/13062021/0A/52/CC/1AF5FC422867D96E06C4B7BD69_1623557926542.png" alt="Background Image" class="object-cover object-center w-full h-full" />

                        </div>
                        <div class="absolute inset-9 flex flex-col md:flex-row items-center justify-between">
                            <div class="md:w-1/2 mb-4 md:mb-0">
                                <h1 class="text-grey-700 font-medium text-4xl md:text-5xl leading-tight mb-2">Bappa Flour mill</h1>
                                <p class="font-regular text-xl mb-8 mt-4">One stop solution for flour grinding services</p>
                                <a href="#contactUs"
                                    class="px-6 py-3 bg-[#c8a876] text-white font-medium rounded-full hover:bg-[#c09858]  transition duration-200">Contact
                                    Us</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- our services section --> */}
                    <OurServices />
                    {/* <!-- about us --> */}
                    <AboutUs />
                    {/* <!-- why us  --> */}
                    <WhyUs />
                    {/* <!-- gallery --> */}
                    <Gallery />
                    {/* <!-- Visit us section --> */}
                    <VisitUs />
                    {/* <!-- footer --> */}
                    <Footer />
                </>
            );
        } catch (err) {
            return (
                <label>Oopss</label>
            );
        }
    }

    return render();
}