import { useState } from "react";
import { useHistory } from "react-router-dom";
import { verifyLogin } from "../../api/api-autenticacion";
import "./login.css";
import spinner from "./spinner4.svg";
import logo from "./logo.png";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  // const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const submit = async (e) => {
    try {
      // console.log('submit');
      e.preventDefault();
      setLoading(true);
      const response = await verifyLogin(usuario, contrasenia);
      // console.log('response');
      // console.log(response);
      setLoading(false);
      if (response.error === false) {
        localStorage.setItem("accessTokenUltimaMilla", response.body.token);
        localStorage.setItem("idEmpresaUltimaMilla", response.body.idEmpresa);
        history.push(`/dashboard`);
      } else {
        alert('Usuario o contrasena incorrecto');
        return;
      }
    } catch (err) {
      console.log('submit err');
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="fs f">
          <div className="login_box">
            <img id="logo" src={logo} alt="Logo Boltrack"></img>
            <span className="title">Plataforma de Ultima Milla</span>
            <div className="login_fields">
              <div className="field_line">
                <span className="fieldname">Usuario:</span>
                <input
                  id="usuario_login"
                  value={usuario}
                  onChange={(e) => {
                    setUsuario(e.target.value);
                  }}
                  className="login_input"
                />
              </div>
              <div className="field_line">
                <span className="fieldname">Contraseña:</span>
                <input
                  id="password_login"
                  value={contrasenia}
                  onChange={(e) => {
                    setContrasenia(e.target.value);
                  }}
                  className="login_input"
                  type="password"
                />
              </div>
              <div className="field_line">
                {loading === false &&
                  <button type="onSubmit" className="login_button">
                    Ingresar
                  </button>
                }
                {loading === true &&
                  <button className="login_button flex justify-center">
                    <img className="spinner" alt="spinner" src={spinner} />
                  </button>}
              </div>
              <div className="flex justify-center">o</div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.1)';
                    e.currentTarget.style.boxShadow = '0 0 10px #0F3457';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                  title="Volver a Landing"
                >
                  <ArrowLeftCircleIcon width={24} color='#0F3457' onClick={() => { history.push(`/landing`); }} />
                </div>
                <span
                  style={{
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                  className="fieldname"
                  onMouseEnter={(e) => (e.target.style.color = '#0F3457')} // Change color on hover
                  onMouseLeave={(e) => (e.target.style.color = '#030A11')}
                  onClick={() => { history.push(`/sign-up`); }}
                >
                  Registrarse
                </span>

              </div>
              <div>
                <label>version 3.0.0</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
