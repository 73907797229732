import { FAILURE, POST, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function validarNit(obj) {
    try {
        let data = { error: true };
        const { code, message, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apiultimamillav3/api/empresa/get-count-nit",
            {
                headers: getHeaders(false),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            data.error = false;
            if (Array.isArray(body) && body.length > 0) {
                data.total = body[0].total;
            }
        }
        if (code === FAILURE) {
            data.error = true;
            data.message = message;
        }
        return data;
    } catch (err) {
        console.log("validarNit err");
        console.log(err);
        return { error: true, message: err };
    }
}

export async function registrarEmpresa(obj) {
    try {
        let data = { error: true };
        const { code, message, body, idEmpresa } = await fetch(
            "https://gestiondeflota.boltrack.net/apiultimamillav3/api/empresa/sign-up",
            {
                headers: getHeaders(false),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            data.error = false;
            data.token = body;
            data.idEmpresa = idEmpresa;
        }
        if (code === FAILURE) {
            data.error = true;
            alert(message);
        }
        return data;
    } catch (err) {
        console.log("registrarEmpresa err");
        console.log(err);
        return { error: true, message: err };
    }
}