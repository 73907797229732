import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Salir() {
  const history = useHistory();
  const clearData = (page, params) => {
    try {
      console.log('clearData');
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
        // history.push(`/`);
      } else {
        history.push(`${page}`);
        // history.push(`/`);
      }
      console.log('end clearData');
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    console.log('useEffect');
    localStorage.clear("idEmpresaUltimaMilla");
    localStorage.clear("accessTokenUltimaMilla");
    clearData("login");
    console.log('end useEffect');
  }, []);

  return <div className="p-[2rem]"></div>;
}
