export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function onParseJWT(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const verifyTokenExpire = (redirect) => {
  try {
    const accessToken = localStorage.getItem("accessTokenUltimaMilla");
    if (!accessToken) throw new Error("No access token");
    const decodedAccessToken = parseJwt(accessToken);
    const expire = decodedAccessToken.exp * 1000;
    const now = new Date().getTime();
    if (now > expire) {
      redirect();
    }
    return expire;
  } catch (e) {
    console.error(e);
    redirect();
  }
};

export const isTokenExpire = () => {
  try {
    const accessToken = localStorage.getItem("accessTokenUltimaMilla");
    if (!accessToken) throw new Error("No access token");
    const decodedAccessToken = parseJwt(accessToken);
    // console.log('decodedAccessToken');
    // console.log(decodedAccessToken);
    const expire = decodedAccessToken.exp * 1000;
    const now = new Date().getTime();
    if (now > expire) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
    return true;
  }
};
