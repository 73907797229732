import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from "./page/login/login";
import RouterPage from "./page/router";
import LandingScreen from "./page/landing/landing-screen";
import SignUpScreen from "./page/login/sign_up";

const RouterMain = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/landing">
        <LandingScreen />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/sign-up">
        <SignUpScreen />
      </Route>
      <Route path="/">
        <RouterPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default RouterMain;
