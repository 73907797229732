const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getHeaders = (token = true) => {
  if (token === true) {
    const bearer = localStorage.getItem("accessTokenUltimaMilla");
    if (typeof bearer !== "string") throw new Error("missing bearer token");
    return {
      ...HEADERS,
      Authorization: `Bearer ${bearer}`,
    };
  }
  if (token === false) {
    return {
      ...HEADERS,
    };
  }
};